<template>
    <el-container>
        <el-main>
            <el-form ref="form" label-width="160px" :rules="rules" :model="form">
                <el-form-item label="普通用户发布费用：" prop="post_price">
                    <el-input v-model="form.post_price" class="inputClass"></el-input>
                        <span style="color: #999; margin-left:10px;">元</span>
                </el-form-item>

                <el-form-item label="会员置顶费用：">
                    <div v-for="(item,index) in form.is_top_price" :key="index" class="flex">
                        <el-input v-model="item.day" class="halfinputClass"></el-input>
                        <span style="color: #999; margin:0 10px;">天</span>
                        <el-input v-model="item.price" class="halfinputClass"></el-input>
                        <span style="color: #999; margin-left:10px;">元</span>
                            <el-link icon="el-icon-circle-close" :underline="false" style="margin-left:10px;font-size:20px;"
                            @click="deleteMembertop(index)"></el-link>
                    </div>
                        <el-link icon="el-icon-plus" :underline="false" @click="addMembertop" type="primary">添加</el-link>
                </el-form-item>
                <Preservation @preservation="submit('form')">
                </Preservation>
            </el-form>
        </el-main>
    </el-container>
</template>

<script>
import { inspectFloat } from '@/util/verification'
import Preservation from '@/components/preservation'
export default {
    components: {
        Preservation
    },
    data(){
        return {
            form:{
                post_price:0,
                is_top_price:[]
            },
            rules:{
                post_price:[{ required: true, message: '请输入普通用户发布费用', trigger: 'blur' },
                { validator: (rule, value, callback) => {
                    if(!inspectFloat(value)){
                        callback(new Error('请输入正确金额'));
                    }else {
                        callback();
                    }
                }, trigger: 'blur' }]
            }
        }
    },
    created(){
        this.getInfo()
    },
    methods:{
        getInfo(){
            this.$axios.post(this.$api.samecity.forum.forumsget)
            .then((res) => {
                if (res.code == 200) {
                    let info = res.result
                    if(info){
                        this.form = {
                            post_price:info.post_price,
                            is_top_price:info.is_top_price
                        }
                    }
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        addMembertop(){
            this.form.is_top_price.push({
                    day:'',
                    price:''
            })
        },
        deleteMembertop(index){
            this.form.is_top_price.splice(index,1)
        },
        submit(){
            this.$axios.post(this.$api.samecity.forum.forumsSet,this.form)
            .then((res) => {
                if (res.code == 200) {
                    this.$message.success('保存成功')
                     this.getInfo()
                } else {
                    this.$message.error(res.msg)
                }
            })
        }
    },
}
</script>

<style scoped lang="less">
.el-container {
    .el-main {
        height: 100%;
        background: #fff;
        display: flex;
        flex-direction: column;
    }
}
    .inputClass{
        width: 700px;
    }
.halfinputClass{
      width:333px
}
.flex{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
</style>
